import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"

const Judge = ({ image, name, bio, home, background }) => {
  const wrapper_style = ctl(`
  flex 
  odd:md:flex-row
  even:md:flex-row-reverse 
  even:md:text-right  
  ${
    home &&
    `odd:md:flex-col 
    even:md:flex-col 
    even:md:text-left `
  }
  flex-col 
  md:relative 
  md:mb-28 
  judge
  `)
  const image_wrapper_style = ctl(`
  ${home ? `w-full lg:h-64 xl:h-80 ` : `md:h-auto md:w-[45%]`} 
  h-[202px]
 
  `)
  const image_style = ctl(`
  filter
  grayscale
  ${
    home
      ? `
  w-full
  h-full
  object-cover
  object-top
  `
      : `
  md:w-auto
  w-full
  md:h-auto
  h-full
  md:object-none
  object-cover

  `
  }
  `)
  const info_wrapper = ctl(`
  md:pr-0 
  info 
  ${home ? `pl-8 pr-0` : `md:w-[55%] md:static   px-8`}
  relative
  `)

  const rectangle_style = ctl(`
  ${background ? background : "bg-sa-yellow"}
  md:w-[134px] 
  w-[100px] 
  md:h-[51px] 
  h-[38px] 
  absolute 
  ${!home && `md:left-[37.5%]  md:-top-2 `}
  left-0 
  z-[-1] 
  marker
  `)

  const name_style = ctl(`
  font-sans 
  font-semibold 
  w-full
  xl:text-3xl
  md:text-2xl 
  text-xl`)
  return (
    <section className={wrapper_style}>
      <div className={image_wrapper_style}>
        <GatsbyImage
          image={getImage(image)}
          alt={name}
          className={image_style}
        />
      </div>
      <div className={info_wrapper}>
        <div className={rectangle_style}></div>
        <h3 className={name_style}>{name}</h3>
        <p>{bio}</p>
      </div>
    </section>
  )
}

Judge.propTypes = {
  image: PropTypes.node,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
}

export default Judge
